<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" align="center" class="firstSection" v-if="!mobile" no-gutters>
      <v-col cols="6">
        <p class="textBlue" v-html="$t('firstSectionText1')"></p>
        <p class="textGrey" v-html="$t('firstSectionText2')"></p>
        <p class="textBlue" v-html="$t('firstSectionText3')"></p>
      </v-col>
      <v-col cols="6">
        <v-img
            :src="require('@/assets/picto/pictoPeople3.svg')"
            contain
            height="420"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="firstSectionMobile" v-if="mobile" no-gutters>
      <v-col cols="6">
        <p class="textBlue" v-html="$t('firstSectionText1')"></p>
      </v-col>
      <v-col cols="4" align-self="center">
        <v-img
            :src="require('@/assets/picto/pictoPeople3.svg')"
            contain
            height="150"
        />
      </v-col>
      <v-col cols="10" align-self="center">
        <p class="textGrey" v-html="$t('firstSectionText2')"></p>
        <p class="textBlue" v-html="$t('firstSectionText3')"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'DeviceComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Our device",
    "firstSectionText1": "Our Maison du Sport Santé (MSS) system is a platform bringing together players in sport, health and social issues in the Cannois, Grasse and Vençois basin to prevent, maintain or improve the state of health, physical well-being, social and mental populations.",
    "firstSectionText2": "It acts as a trusted resource and expertise center for the health sport community, to assess and guide, on the basis of a medical prescription, people who are advanced in age or suffering from chronic diseases towards an Adapted Physical Activity. (APA) </strong> to the pathology, physical capacities and or medical risk. Orientation is done as close as possible to the place of life, in a sports structure with specific supervision. <br> <br> In close ties with the communities, the long-term objective is to be an actor in the revitalization of the territory and the maintenance of good health of the populations.",
    "firstSectionText3": "«Sport is indeed a useful and promising tool for promoting physical and emotional health and strengthening social ties [wilhite and shank, 2009] » [wilhite et shank, 2009]",
    "secondSectionTitle": "Blabla sur le rôle de chacun dans la prise en charge d'un patient",
    "secondSectionText": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. <br><br> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.",
    "thirdSectionTitle": "Preferred areas of intervention of the different trades"
  },
  "fr": {
    "mainTitle": "Notre dispositif",
    "firstSectionText1": "Notre dispositif issu du programme des Maisons Sport-Santé (MSS), est une plateforme regroupant sur le bassin Cannois, Grassois et Vençois, les acteurs du sport, de la santé et du social pour prévenir, maintenir ou améliorer l’état de santé, le bien-être physique, sociale et mental des populations.",
    "firstSectionText2": "Elle intervient comme un centre de ressource et d’expertise de confiance de la communauté du sport santé, pour évaluer et orienter sur la base d’une prescription médicale, des personnes avancées en âge ou atteintes de maladies chroniques vers une <strong> Activité Physique Adaptée (APA) </strong> à la pathologie, aux capacités physiques et au risque médical. L’orientation est faite au plus près du lieu de vie, dans une structure sportive disposant de l’encadrement spécifique. <br><br> En liens étroits avec les collectivités, l’objectif à long terme, est d’être acteur de la dynamisation du territoire et du maintien en bonne santé des populations.",
    "firstSectionText3": "« Le sport est en effet un outil utile et prometteur pour favoriser la santé physique et émotionnelle et le renforcement des liens sociaux » [Wilhite et Shank, 2009]",
    "secondSectionTitle": "Blabla sur le rôle de chacun dans la prise en charge d'un patient",
    "secondSectionText": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. <br><br> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.",
    "thirdSectionTitle": "Domaines d'intervention préférentiels des différents métiers"
  }
}
</i18n>

<style scoped>

.firstSection {
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 60px;
}

.firstSectionMobile {
  font-size: 14px;
  padding-bottom: 40px;
}

.secondSection {
  background-color: #D5EEF4;
  padding: 50px 200px;
  color: #1579A7;
}

.secondSectionTitle {
  padding-right: 100px;
  font-family: Comfortaa, sans-serif;
}

.thirdSectionTitle {
  text-align: center;
  padding-left:600px;
  padding-right:600px;
}

.thirdSection {
  padding-bottom: 50px;
}

.textGrey {
  color: #808080;
}

.textBlue {
  color: #57939E;
}

</style>
