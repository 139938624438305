<template>
  <div>
    <DeviceComp v-bind:mobile="mobile"/>
    <FollowUs v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import DeviceComp from '@/components/desktop/vitaeHealthHouse/DeviceComp'
import FollowUs from "@/components/desktop/FollowUs";

  export default {
    name: 'Device',
    props: ['mobile'],
    components: {
      FollowUs,
      DeviceComp
    },
  }
</script>
